.groupHeadline
  background-color: #f0f0f0
  color: #666
  font-size: .875rem
  font-weight: normal
  padding: .25em .5em
  text-align: left

.section
  ul
    list-style: none
    margin: 0
    padding: 0
    text-align: left
