.form
  position: relative

  :global(.inside)
    position: relative

  :global(.formWrapper)
    align-items: stretch
    background-color: #fff
    border-radius: .5rem
    box-shadow: 0 2px 3px rgba(#000, .3)
    display: flex
    justify-content: space-between
    height: 3rem
    overflow: hidden
    position: relative

  :global(.button)
    flex-grow: 0
    flex-shrink: 0

  :global(.input)
    border: 0
    flex-grow: 1
    flex-shrink: 1
    font-size: 1.1rem
    outline: none
    padding: .5rem .75rem
    text-transform: uppercase
    width: 100%

    &::placeholder
      text-transform: none

.countyButton
  border: 0
  border-radius: .5rem
  justify-content: flex-end
  margin: 0
  position: relative
  width: 4.5rem

  :global(.icon)
    margin-left: .5rem

  &::after
    background-color: #ccc
    bottom: .5rem
    content: ''
    position: absolute
    right: 2px
    top: .5rem
    width: 1px

.formError
  background-color: #fffbe6
  border-color: #ffe58f
  border-radius: .5rem
  color: #666
  margin: .5rem .25rem
  padding: 1rem 1rem

.countryHint
  align-items: center
  color: #999
  display: flex
  font-size: .875rem
  justify-content: flex-start
  margin-left: 1.4rem
  margin-top: .5rem

  > span
    color: #ccc
    font-size: 1.25rem
    margin-bottom: 1.1rem
    margin-right: .25rem

.btnContainer
  display: flex
  flex-direction: column
  margin-top: 2rem

.createPinBtnContainer
  display: flex
  justify-content: center

.btnCreatePin
  margin-top: 0
  background-color: #FFF
  border-color: #d6d6d6
  &:hover
    background-color: #e7e7e7

.btnSignIn
  margin-top: 0
