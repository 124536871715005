.heading
  margin-bottom: 20px
  display: none
  @media (min-width: 768px)
    display: block

.examplesNote
  color: #666
  font-size: .875rem
  margin-bottom: 20px
  text-align: center
