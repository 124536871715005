@import styles/_variables

.containerHoveredAbove
  border-top: 3px solid #5ca0ff

.containerHoveredBelow
  border-bottom: 3px solid #5ca0ff

.containerInside
  align-items: flex-start
  display: flex
  justify-content: space-between
  padding-bottom: 1em
  padding-top: 1em

.containerContent
  max-width: 100%
  overflow: hidden

.dragIconContainer
  align-items: center
  display: flex
  flex-grow: 0
  flex-shrink: 0
  justify-content: center
  width: 40px

.dragIcon
  color: #808080
  cursor: move
  font-size: 28px

.abilityNote
  background-color: lighten($color-info, 15%)
  border-bottom: 1px solid darken($color-info, 5%)
  border-top: 1px solid darken($color-info, 5%)
  padding: .5em

  p
    color: #666
    font-size: .875em
    margin: 0
    padding: 0

  p + p
    font-weight: 500
    margin-top: .25em

  button
    background: none
    border: 0
    padding: 0
    text-decoration: underline

    &:hover
      color: $color-primary
