@import styles/_variables

.spinner
  display: inline-block
  position: relative
  width: 1.25em
  height: 1.25em

  div
    box-sizing: border-box
    display: block
    position: absolute
    width: 1.25em
    height: 1.25em
    border: .15em solid #fff
    border-radius: 50%
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
    border-color: #fff transparent transparent transparent

  div:nth-child(1)
    animation-delay: -0.45s

  div:nth-child(2)
    animation-delay: -0.3s

  div:nth-child(3)
    animation-delay: -0.15s

.spinnerDark
  div
    border-color: $color-primary transparent transparent

@keyframes lds-ring
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
