.container
  align-items: center
  display: flex
  flex-direction: column

  p
    font-size: 1em
    text-align: center
    color: #666

  .headline
    color: #00cc65,
    font-size: 1.25em

  .boldParagraph
    font-weight: bold
    font-size: 1.2em