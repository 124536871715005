.table
  border: 1px solid #f0f0f0
  border-collapse: collapse
  border-radius: 2px
  margin-top: 1em
  table-layout: auto
  width: 100%

.row
  border-bottom: 1px solid #f0f0f0

  th,
  td
    color: rgba(0, 0, 0, 0.85)
    font-size: .875em
    font-weight: normal
    padding: 16px 24px
    text-align: start

.title
  background-color: #fafafa
  border-right: 1px solid #f0f0f0

.desc
  word-break: break-word
  overflow-wrap: break-word
