@import styles/_variables

.inputCluster
  display: flex
  font-size: 20px

.inputField
  font-size: 1rem
  text-align: center
  height: 2em
  width: 2em
  border: 1px solid #d0d0d0
  border-radius: 0.3rem
  padding: 0.25em
  margin-left: 0.25em
  margin-right: 0.25em

  &:first-child
    margin-left: 0
