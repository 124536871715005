@import styles/_variables

.input
  border: 1px solid #d0d0d0
  border-radius: .3rem
  font-size: 1rem
  height: 2rem
  padding: .5rem
  width: 100%

  &::placeholder
    color: #bbb
    font-size: 1rem

.inputSuccess
  border-color: $input-color-success

.inputError
  border-color: $input-color-error

.inputLarge
  font-size: 1.1rem
  height: 3rem
  padding: .75rem
