@import styles/_variables

.button
  align-items: center
  background-color: #eee
  border: 0
  border-radius: .5rem
  box-shadow: 0 2px 3px rgba(#000, .1)
  color: #ccc
  display: inline-flex
  font-size: 1rem
  height: 3rem
  inset: 0
  justify-content: center
  line-height: 1
  margin: 0
  outline: 0
  overflow: hidden
  position: relative
  text-align: center
  text-transform: uppercase
  width: 3.5rem

.buttonEnabled
  background-color: $color-primary
  box-shadow: 0 2px 3px rgba(#000, .3)
  color: #fff

  &:hover,
  &:active
    background-color: lighten($color-primary, 3%)

.buttonLoading
  background-color: lighten($color-primary, 3%)
