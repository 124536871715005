.modal
  display: flex
  flex-direction: column

.modalContent
  overflow: auto
  padding: 20px

.footer
  display: flex
  justify-content: flex-end
  border-top: 1px solid #f0f0f0
  border-radius: 0 0 2px 2px
  text-align: right
  padding: 10px 16px

  button+button
    margin-left: 8px

.modalContentNoPadding
  padding: 0

.modal :global(.modal-header)
  flex-grow: 0
  flex-shrink: 0
