@import styles/_variables

.header
  align-items: stretch
  border-bottom: 1px solid #f0f0f0
  display: flex
  flex-direction: row
  flex-grow: 0
  flex-shrink: 0
  height: $modal-header-height
  justify-content: space-between

.colIcon
  align-items: stretch
  display: flex
  flex-grow: 0
  flex-shrink: 0
  height: $modal-header-height
  justify-content: center
  position: relative
  width: $modal-header-height

.title
  align-items: center
  color: rgba(#000, .85)
  display: flex
  flex-grow: 1
  flex-shrink: 1
  font-size: 1.25em
  justify-content: center
  text-align: center

.button
  align-items: center
  background-color: transparent
  border: none
  display: flex
  flex-grow: 1
  justify-content: center
  padding: 6px 10px

  &:hover,
  &:active
    background-color: #f0f0f0

  &:disabled
    opacity: 10%

    &:hover
      background-color: transparent
