.button
  align-items: center
  background-color: transparent
  border: 1px solid #ddd
  border-radius: 999px
  color: #666
  display: inline-flex
  font-size: 1rem
  inset: 0
  justify-content: center
  margin: .5rem 0
  outline: 0
  overflow: hidden
  padding: .5rem .75rem
  position: relative
  text-align: center
  width: auto

  &:hover,
  &:active
    background-color: rgba(#000, .1)
    border-color: #aaa

.icon
  margin-left: .25rem
