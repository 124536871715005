.button
  background: transparent
  border: 0
  border-radius: 3px
  color: rgba(#fff, .85)
  font-size: 1.1rem
  outline: 0
  padding: .6em 1.2em
  text-shadow: 0 0 2px rgba(#000, .25)

  &:hover
    background-color: rgba(#fff, .05)
