.tutorialOverlay
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  background-color: rgba(0, 0, 0, 0.5)
  z-index: 99999
  cursor: pointer

.tutorialSearch
  position: relative
  color: #ffffff
  display: inline-block
  top: 52px
  font-size: 24px
  pointer-events: none

  > svg
    display: inline
    margin-right: 5px

  @media (max-width: 550px)
    padding-left: 50px
    padding-right: 50px

.tutorialDrag
  position: relative
  color: #ffffff
  display: inline-block
  top: 30%
  font-size: 24px
  max-width: 300px
  pointer-events: none

  > svg
    display: inline
    margin-right: 5px

.tutorialMovePin
  position: relative
  color: #ffffff
  display: inline-block
  top: 30%
  font-size: 24px
  max-width: 300px
  pointer-events: none

  > svg
    margin: 0 0 0 48%

.tutorialLocation
  position: absolute
  color: #ffffff
  display: inline-block
  right: 25px
  top: 175px
  font-size: 24px
  max-width: 300px
  line-height: 24px
  pointer-events: none

  > svg
    display: inline
    margin-left: 5px

.tutorialZoom
  position: absolute
  color: #ffffff
  display: inline-block
  top: 70px
  left: 16px
  font-size: 24px
  pointer-events: none

  > svg
    display: inline
    margin-right: 5px

.closeBtn
  color: #ffffff
  display: inline-block
  display: flex
  font-size: 24px
  align-items: center
  justify-content: center

  > svg
    display: inline
    margin-right: 6px

.nextBtn
  color: #ffffff
  display: inline-block
  display: flex
  font-size: 24px
  align-items: center
  justify-content: center

  > svg
    display: inline
    margin-right: 6px

.btnGroup
  position: absolute
  color: #ffffff
  display: inline-block
  bottom: 23px
  left: 0
  right: 0
  display: flex
  font-size: 24px
  align-items: center
  justify-content: space-between
  padding-left: 30px
  padding-right: 30px

