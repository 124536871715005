.container
  align-items: center
  display: flex
  flex-direction: row
  justify-content: center

  a
    display: block
    margin: 0 .15em
    position: relative

    &:first-child
      margin-left: .75em
