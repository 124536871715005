@import styles/_variables

.container
  height: 93px
  pointer-events: none
  position: fixed
  width: 93px
  z-index: $z-index-staging-note

.containerTopLeft
  background: url(/assets/images/staging_note_topLeft.png)
  background-repeat: no-repeat
  left: 0
  top: 0

.containerBottomRight
  background: url(/assets/images/staging_note_bottomRight.png)
  background-repeat: no-repeat
  bottom: 0
  right: 0
