.wrapper
  small
    color: #999

.imgGoogle
  height: 12px
  margin-right: 8px
  width: 12px

.imgApple
  height: 14px
  margin-right: 8px
  margin-top: -3px
  width: 14px

.imgFacebook
  height: 13px
  margin-right: 8px
  width: 13px

.uid,
.email
  font-size: 12px
  margin: 0

.dataModalPre
  font-size: 12px

.btnConnect
  border-radius: 2px
  border-color: #d9d9d9

.connectedContainer
  text-align: left
