@import styles/_variables

.wrapper
  left: 0
  right: 0
  position: sticky
  top: 0
  width: 100%
  z-index: $z-index-header

.headerBackground
  background-color: #fff
  border-bottom: 1px solid #dfdfdf

.header
  align-items: center
  display: flex
  flex-direction: row
  height: $header-height
  text-align: left
  width: 100%

.container
  align-items: center
  display: flex
  flex-direction: row
  justify-content: space-between
  position: relative

.colDesktopLogo
  flex-grow: 0
  flex-shrink: 0
  margin-left: 1em

.colMobileTitle
  flex-grow: 1
  flex-shrink: 1
  font-size: 1rem
  font-weight: bold
  margin: 0
  text-align: center

.colDesktopNavigation
  align-items: stretch
  flex-direction: row
  flex-grow: 1
  flex-shrink: 1
  height: $header-height
  justify-content: flex-end
  list-style: none
  margin: 0 0 0 1em

  li
    align-items: stretch
    display: flex

  a
    align-items: center
    color: rgba(#000, .85)
    display: flex
    font-size: 1.05rem
    font-weight: 400
    padding: .5em 1em
    text-decoration: none
    transition: background-color .3s, color .3s

    &:hover
      background-color: $color-primary
      color: #f0f0f0


.colIcon
  align-items: stretch
  display: flex
  flex-grow: 0
  flex-shrink: 0
  height: $header-height
  justify-content: center
  position: relative
  width: $header-height

.colIconRight
.colIconLeft

.iconAccount
  font-size: 1.2em
