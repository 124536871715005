@import styles/_variables

.errors
  color: $input-color-error

  p
    margin-top: .25em
  
.heading
  margin-bottom: 20px
  display: none
  @media (min-width: 768px)
    display: block