@import styles/_variables

.wrapper
  display: block
  // left: 55px
  // position: absolute
  // right: 12px
  // top: 12px
  // z-index: 1010

.suggestions
  background-color: #fff
  border-radius: 5px
  border: 1px solid #aaa
  display: block
  list-style: none
  margin: 5px 0 0 0
  overflow: hidden
  padding: 0

  li
    border-bottom: 1px solid #f0f0f0

    &:last-child
      border-bottom: none

  button
    background-color: #fff
    border: none
    display: block
    padding: .5em 1em
    text-align: left
    width: 100%

    &:hover
      background-color: #f0f0f0
      cursor: pointer

    &:active,
    &:focus
      background-color: lighten($color-primary, 50%)
