.dropzone
  border: 1px dotted #aaaaaa
  color: #666666
  border-radius: 4px
  margin: 0
  padding-top: 1em
  font-size: 0.8em
  cursor: pointer

  p
    margin-bottom: 1em
