@import styles/_variables

.button
  border: none
  background: none
  display: block
  width: 100%

.inputSuccess
  border-color: $input-color-success

.inputError
  border-color: $input-color-error

.error
  color: $input-color-error
  display: block
  font-size: .75em
  margin: 0 .5em .25em
  text-align: right
