.link
  align-items: center
  background-color: transparent
  border: none
  cursor: pointer
  color: #000
  display: flex
  justify-content: space-between
  padding: .5em .75em
  text-decoration: none
  width: 100%

  &:hover
    background-color: #f0f0f0

.title
  margin: 0
  font-size: 1em
  color: #000

.vayapin
  font-size: 12px
  margin-bottom: 0
  color: gray

.container
  text-align: left
  margin-left: 16px
  flex-grow: 1
  flex-shrink: 1
