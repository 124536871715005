@import styles/_variables

.container
  margin-top: .5em
  max-width: 100%

.textarea
  border-radius: .25em
  border: 1px solid #ddd
  max-width: 100%
  padding: .75em

.actions
  align-items: flex-start
  display: flex
  justify-content: space-between

.removeBtn
  color: #666
  background: none
  border: 0
  flex-grow: 0
  flex-shrink: 0
  font-size: .875em
  margin-right: 1em
  padding: 0

  &:hover
    text-decoration: underline
    color: $input-color-error

.note
  color: #666
  display: block
  flex-grow: 1
  font-size: .875em
  text-align: right

.inputSuccess
  border-color: $input-color-success

.inputError
  border-color: $input-color-error

.error
  color: $input-color-error
  display: block
  font-size: .75em
  margin: 0 .5em .25em
  text-align: right
