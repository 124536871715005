.button
  align-items: center
  border: 0
  border-bottom: 1px solid #f0f0f0
  display: flex
  height: 56px
  justify-content: space-between
  padding: 0
  margin: 0
  width: 100%

  &:hover
    background-color: rgba(#000, .05)

  :global(.flag),
  :global(.chevron)
    flex-grow: 0
    margin: 0 1em

  :global(.content)
    flex-grow: 1
    text-align: left
