@import styles/_variables

.button
  align-items: center
  border: none
  background: transparent
  color: rgba(#000, .85)
  display: flex
  flex-grow: 1
  justify-content: center
  margin: 0
  outline: none
  transition: background-color .3s, color .3s

  &:hover,
  &:active
    background-color: $color-primary
    color: #fff
