@import styles/_variables

.logo
  align-items: center
  display: flex
  justify-content: center
  margin-bottom: 3rem

  :global(.inside)
    // width: 80%
    width: 70%
    height: 75px
    margin-left: -1rem
    max-width: 95%
    position: relative

@media all and (min-width: map-get($grid-breakpoints, "sm") * 0.66)
  .logo
    :global(.inside)
      width: 60%

@media all and (min-width: map-get($grid-breakpoints, "sm"))
  .logo
    :global(.inside)
      width: 50%

@media all and (min-width: map-get($grid-breakpoints, "md"))
  .logo
    :global(.inside)
      height: 65px
      max-width: 250px
      width: 80%
