.wrapper
  input
    background-color: #fff
    border-radius: 5px
    border: 1px solid #aaa
    flex-grow: 1
    padding: .5rem 50px .5rem .5rem
    width: 100%

.spinner
  position: absolute
  right: 8px
  top: 8px
