@import styles/_variables

.select
  border: 1px solid #d0d0d0
  border-radius: .3rem
  font-size: 1rem
  height: 2rem
  padding: 0 0.5rem
  width: 100%

  &::placeholder
    color: #bbb
    font-size: 1rem

  &:disabled
    background-color: $input-select-bg-disabled

.selectSuccess
  border-color: $input-color-success

.selectError
  border-color: $input-color-error

.selectLarge
  font-size: 1.1rem
  height: 3rem
  padding: .75rem
