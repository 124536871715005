@import styles/_variables

.headline
  font-size: 1.5rem
  margin-bottom: 1.5em

.sectionLabel
  color: #666
  display: block
  font-size: .875em
  margin: .5em 0
  text-align: left

.pinSelectionContainer
  border: 1px solid #f0f0f0
  border-radius: .5em
  margin: .25em 0
  padding: .75em
  text-align: left

.pinSelectionOptionsContainer
  display: grid

  & > span
    color: #999
    display: inline-block
    font-size: .875em
    margin: .5em 0

  label:focus-within
    color: $color-primary
    font-family: system-ui, sans-serif
    font-size: 2rem
    font-weight: bold
    line-height: 1.1
    display: grid
    grid-template-columns: 1em auto
    gap: 0.5em

.pinSelectionContainer
  input[type="radio"]
    // Add if not using autoprefixer
    -webkit-appearance: none
    // Remove most all native input styles
    appearance: none
    // For iOS < 15
    background-color: #fff
    // Not removed via appearance
    margin: 0

    font: inherit
    color: #666
    width: 1.25em
    height: 1.25em
    border: 1px solid currentColor
    border-radius: 50%
    transform: translateY(-0.075em)

    display: grid
    place-content: center

  input[type="radio"]::before
    content: ""
    width: 0.65em
    height: 0.65em
    border-radius: 50%
    transform: scale(0)
    transition: 120ms transform ease-in-out
    box-shadow: inset 1em 1em $color-primary
    // Windows High Contrast Mode
    background-color: CanvasText

  input[type="radio"]:checked::before
    transform: scale(1)

  input[type="radio"]:hover
    cursor: pointer

.inputContainer
  border-bottom: 1px solid #eee
  margin-bottom: .75em
  padding-bottom: .75em

.noSpecialChars
  color: darken($color-info, 15%)
  font-size: .7em
  margin-top: 1em
  margin-right: 8px
  text-align: center

.radioContainer
  align-items: center
  display: flex
  justify-content: flex-start

  & > input[type="radio"]
    flex-grow: 0
    flex-shrink: 0
    margin: 0 .75em

  & > div,
  & > span,
  & > label
    flex-grow: 1
    margin: 0

  & > label:hover
    cursor: pointer
