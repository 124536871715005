.container
  height: 750px
  max-height: 80vh
  overflow: auto

.content
  display: flex
  flex-direction: column
  flex-grow: 1
  flex-shrink: 1

.listWrapper
  flex-grow: 1
  flex-shrink: 1
  overflow: hidden
  position: relative
