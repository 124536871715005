@import styles/_variables

.container
  background-color: #3d3d3f
  background-image: url('../../../assets/images/navigation/gray-pattern-bg.png')
  background-repeat: repeat
  border-radius: 8px
  color: #fff
  overflow: hidden

  ul
    list-style: none
    margin: 1rem 0
    padding: 0

  li
    margin: 0
    padding: 0

  a, button
    align-items: center
    color: #fff
    display: flex
    font-size: 1.1rem
    text-decoration: none

    &:hover,
    &:active
      background-color: $color-primary
      color: #fff

    &:hover
      cursor: pointer

.containerDrawer
  border-top-left-radius: 0
  border-top-right-radius: 0
  border-bottom-left-radius: 32px
  border-bottom-right-radius: 32px

.li
  a, button
    align-items: center
    justify-content: flex-end
    padding: .5rem .5rem .5rem 1rem
    text-align: right

    span
      white-space: nowrap

    svg
      margin: 0 .5rem

  &:global(.separator)
    border-bottom: 1px solid rgba(#fff, .2)
    height: 0
    margin-bottom: .25em
    margin-top: .25em

.liDrawer
  a
    justify-content: flex-start
    padding: 1rem 1rem 1rem 1rem
    text-align: left

    svg
      margin: 0 .5rem

.logo
  max-width: 90%
  margin-top: 1rem
  width: 200px
  padding: .5rem 1.5rem

  &:hover,
  &:active
    background-color: transparent

.container a.logo
  &:hover,
  &:active
    background-color: transparent

