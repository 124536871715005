@import styles/_variables

.item
  display: block
  flex-direction: column
  margin-bottom: .5em
  text-align: left

  & > label
    color: #333
    font-size: .875rem
    margin: .1rem 0

  & > label:hover
    cursor: pointer

.itemSuccess
  & > label
    color: $input-color-success

.itemError
  & > label
    color: $input-color-error

.error
  color: $input-color-error
  display: block
  font-size: .75em
  margin: .25em .5em 0 0
  text-align: right
