.container
  background-color: #fff
  border-radius: .5rem
  box-shadow: 0 2px 3px rgba(#000, .3)
  margin-top: 2px
  position: absolute
  padding-bottom: .5rem
  top: 100%
  width: 100%
  z-index: 100

  ul
    list-style: none
    margin: 0
    padding: 0

  :global(.group)
    color: #999
    font-size: .75rem
    margin-top: .5rem
    padding: .5rem 1rem 0 1rem
    text-transform: uppercase

  :global(.item)

    button
      align-items: center
      display: flex
      justify-content: space-between
      padding: .75rem 1rem
      text-align: left
      width: 100%

      &:hover,
      &:active
        background-color: #f0f0f0

      :global(.flag),
      :global(.icon)
        flex-grow: 0
        flex-shrink: 0

      :global(.flag)
        margin-right: .25rem
        height: 15px

      :global(.vayapin)
        flex-grow: 1
        flex-shrink: 1
        font-size: 1.1rem

      :global(.icon)
        color: #666
        font-size: .75rem
