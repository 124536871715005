.wrapper
  position: relative

.overlay
  align-items: center
  background-color: rgba(#fff, .5)
  bottom: 0
  display: flex
  flex-direction: column
  justify-content: center
  left: 0
  position: absolute
  right: 0
  top: 0
  z-index: 1005

.label
  margin-top: .25em
