@import styles/_variables

.row
  align-items: center
  display: flex
  justify-content: flex-start
  padding: .5em 1em

  &:hover
    background-color: lighten($color-primary, 25%)
    cursor: pointer

.rowSelected
  background-color: lighten($color-primary, 20%)

.rowFocused
  background-color: lighten($color-primary, 25%)

.rowAsInput
  pointer-events: none
  left: 0
  position: absolute
  right: 0
