@import styles/_variables

.layout
  align-items: center
  background: transparent
  background-image: url('../../../assets/images/bg_pin_light.svg')
  background-position: 50% 30%
  background-repeat: no-repeat
  background-size: 110% auto
  display: flex
  flex: auto
  flex-direction: column
  justify-content: space-between
  min-height: 100%
  padding-left: 6px
  width: 100%

  &.layoutGreen
    background: linear-gradient(#00CC65, #00C35E, #01A948)

@media all and (min-width: 350px)
  .layout
    background-position: 50% 40%

@media all and (min-width: map-get($grid-breakpoints, "sm"))
  .layout
    background-size: 600px auto

.layoutInside
  align-items: stretch
  display: flex
  flex-direction: column
  justify-content: center
  flex-grow: 1
  flex-shrink: 1
  padding-top: 1.5rem
  max-width: 90%
  width: $layout-width
