@import styles/_variables

.listItem
  display: flex
  flex-direction: row
  align-items: flex-start

.formFieldList
  width: 100%
  display: flex
  flex-direction: column
  align-items: flex-start

.removeButton
  color: #666
  background: none
  border: 0
  flex-grow: 0
  flex-shrink: 0
  font-size: .875em
  margin-right: 1em
  padding: 8px 0

  &:hover
    text-decoration: underline
    color: $input-color-error

.item
  width: 100%

.divider
  width: 100%

.dragIcon
  margin-right: 14px
  margin-top: 14px

.containerHoveredAbove
  border-top: 3px solid #5ca0ff

.containerHoveredBelow
  border-bottom: 3px solid #5ca0ff

