.container
  align-items: center
  display: flex
  flex-direction: column

  p
    font-size: 1rem
    margin: 1em 2em
    text-align: center
    font-weight: 500

.btnContainer
  text-align: 'center'
  margin-bottom: 20px

.email
  color: #2600ff
  font-weight: bold
