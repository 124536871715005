@import styles/_variables

.errors
  color: $input-color-error

  p
    margin-top: .25em

.contentInside
  padding: 12px 24px
