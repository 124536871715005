.container
  display: flex
  justify-content: center
  align-items: center
  margin: 8px 0
  width: 100%
  a
    font-size: .85em
    color: #999
    text-decoration: none

.split
  margin: 0 5px
  color: #999
  font-size: .85em
