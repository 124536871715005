.item

  a
    align-items: center
    border-bottom: 1px solid #f0f0f0
    color: #000
    display: flex
    justify-content: space-between
    padding: .5em 1em
    text-decoration: none

  :global(.icon),
  :global(.chevron)
    color: #999
    flex-grow: 0

  :global(.icon)
    font-size: 26px
    width: 50px

    svg
      height: 50px

  :global(.content)
    align-items: flex-start
    display: flex
    flex-direction: column
    flex-grow: 1

    span + span
      color: #666
      font-size: .875em

  &:hover
    background-color: rgba(#000, .05)
