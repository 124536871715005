.navigation
  align-items: center
  display: flex
  justify-content: space-around

  :global(.button-space)
    flex-grow: 0
    flex-shrink: 0
    min-height: 1px
    width: 100px

  :global(#tutorial-dots)
    // bottom: -25px
    align-items: center
    display: flex
    flex-grow: 1
    flex-shrink: 1
    justify-content: center
    list-style: none
    margin: 0
    padding: 0
    position: relative
    text-align: center

    li
      cursor: pointer
      display: inline-block
      height: 30px
      margin: 0 3
      padding: 0
      position: relative
      width: 25px

    button
      background: transparent
      border: 0
      color: transparent
      cursor: pointer
      display: block
      font-size: 0
      height: 30px
      line-height: 0
      outline: none
      padding: 0
      width: 25px

      &:hover
        background-color: rgba(#fff, .05)

      &::before
        color: black
        content: "•"
        font-family: "slick"
        height: 30px
        left: 0
        line-height: 30px
        font-size: 12px
        opacity: 0.25
        position: absolute
        text-align: center
        top: 0
        width: 25px
        -webkit-font-smoothing: antialiased
        -moz-osx-font-smoothing: grayscale

    li
      &:global(.slick-active)
        button
          &::before
            color: #fff
            opacity: 1
            text-shadow: 0 0 2px rgba(#000, .3)

