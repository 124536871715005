@import styles/_variables

.container
  align-items: center
  display: flex
  justify-content: flex-start
  padding: .5em 1em
  text-align: left

  & > span
    margin-left: .75em

  &:hover
    background-color: lighten($color-primary, 25%)
    cursor: pointer

.containerAsInput
  pointer-events: none
  left: 0
  position: absolute
  right: 0

.containerSelected
  background-color: lighten($color-primary, 25%)

.titleContainer
  margin-left: 10px

.title
  margin: 0
  font-size: 1em
  color: black

.subtitle
  font-size: 12px
  margin-bottom: 0
  color: gray
