.field
  text-align: right
  margin-bottom: 15px
  @media (max-width: 480px)
    text-align: center

.label
  font-size: .85em
  padding: .3em
  margin-right: .8em
  min-width: 150px

.input
  fon-size: 1em
  border: .5px solid #d0d0d0
  border-radius: .3em
  width: 60%
  height: 2em
  padding: .5em

.required
  color: #f00
  font-size: 1em

.error
  color: #f00
  font-size: .75em
