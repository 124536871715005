@import styles/_variables

.container

  h2
    font-size: 1.25rem

    &:global(.highlight)
      color: $color-secondary

  p
    color: #666
    line-height: 1.1

.slideImageContainer
  align-items: center
  display: flex
  justify-content: center
  position: relative
  z-index: 1

// ----------------------------------------
// Slide 1
// ----------------------------------------
.slide1ImageContainer
  // 74x118 (0,62711864)
  :global(.arrow)
    height: 70px
    position: absolute
    top: 0
    width: 44px
    z-index: 2
    transform: translate(80px, -10px) rotate(30deg)

@media all and (min-width: 350px)
  .slide1ImageContainer
    margin-top: 16px

    :global(.arrow)
      height: 90px
      width: 57px
      transform: translate(95px, -15px) rotate(30deg)

@media all and (min-width: 556px)
  .slide1ImageContainer
    margin-top: 32px

    :global(.arrow)
      height: 118px
      width: 74px
      transform: translate(105px, -35px) rotate(30deg)

// ----------------------------------------
// Slide 2
// ----------------------------------------
.slide2ImageContainer
  margin-bottom: 30px

  // 50x93 (0,53763441)
  :global(.arrow)
    bottom: 0
    height: 56px
    position: absolute
    width: 30px
    z-index: 2
    transform: translate(55px, 18px)

@media all and (min-width: 350px)
  .slide2ImageContainer
    margin-bottom: 45px

    :global(.arrow)
      height: 75px
      width: 40px
      transform: translate(68px, 25px)

@media all and (min-width: 556px)
  .slide2ImageContainer
    margin-bottom: 50px

    :global(.arrow)
      height: 93px
      width: 50px
      transform: translate(68px, 40px)

.slide2LogoImageContainer
  margin-top: 30px

// ----------------------------------------
// Slide 3
// ----------------------------------------
.slide3ImageContainer
  margin-bottom: 30px

  // 89x139 (0,64028777)
  :global(.arrow)
    bottom: 0
    height: 110px
    position: absolute
    width: 70px
    z-index: 2
    transform: translate(20px, 0px)

@media all and (min-width: 350px)
  .slide3ImageContainer
    :global(.arrow)
      height: 139px
      width: 89px
      transform: translate(20px, 20px)

.slide3LogoImageContainer
  margin-top: 30px

  img
    max-width: 50%

// ----------------------------------------
// Slide 4
// ----------------------------------------
.slide4ImageContainer
  margin-bottom: 30px

  // 44x136 (0,32352941)
  :global(.arrow)
    bottom: 0
    height: 102px
    position: absolute
    width: 33px
    z-index: 2
    transform: translate(-65px, 5px)

@media all and (min-width: 350px)
  .slide4ImageContainer
    margin-bottom: 40px

    :global(.arrow)
      height: 136px
      width: 44px
      transform: translate(-70px, 30px)

.slide4LogoImageContainer
  margin-top: 30px
  margin-bottom: 15px

// ----------------------------------------
// Slide 5
// ----------------------------------------
.slide5ImageContainer
  margin-bottom: 30px

  // 35x82 (0,42682927)
  :global(.arrow)
    bottom: 0
    height: 65px
    position: absolute
    width: 28px
    z-index: 2
    transform: translate(-58px, 32px)

@media all and (min-width: 350px)
  .slide5ImageContainer
    margin-bottom: 40px

    :global(.arrow)
      height: 82px
      width: 35px
      transform: translate(-70px, 40px)

.slide5LogoImageContainer
  margin-top: 30px
  margin-bottom: 15px

// ----------------------------------------
// Slide 6
// ----------------------------------------
.slide6ImageContainer
  margin-bottom: 30px

@media all and (min-width: 350px)
  .slide6ImageContainer
    margin-bottom: 40px

// ----------------------------------------
// Slide 7
// ----------------------------------------
.slide7ImageContainer
  margin-bottom: 30px

  // 35x165 (0,21212121)
  :global(.arrow)
    bottom: 0
    height: 130px
    position: absolute
    width: 28px
    z-index: 2
    transform: translate(-60px, 20px)

@media all and (min-width: 350px)
  .slide7ImageContainer
    margin-bottom: 40px

    :global(.arrow)
      height: 165px
      width: 35px
      transform: translate(-65px, 35px)

.slide7LogoImageContainer
  margin-top: 30px
  margin-bottom: 15px

// ----------------------------------------
// Slide 8
// ----------------------------------------
.slide8ImageContainer
  margin-bottom: 30px

  // 35x165 (0,21212121)
  :global(.arrow)
    bottom: 0
    height: 130px
    position: absolute
    width: 28px
    z-index: 2
    transform: translate(-50px, 25px)

@media all and (min-width: 350px)
  .slide8ImageContainer
    margin-bottom: 40px

    :global(.arrow)
      height: 165px
      width: 35px
      transform: translate(-55px, 40px)

.slide8LogoImageContainer
  margin-top: 30px
  margin-bottom: 15px

  img
    max-width: 50%
