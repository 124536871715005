@import styles/_variables

.button
  align-items: center
  border: none
  background: transparent
  color: rgba(#000, .85)
  display: flex
  flex-grow: 1
  font-size: 1.4rem
  justify-content: center
  margin: 0
  outline: none
  transition: background-color .3s, color .3s

  &:hover,
  &:active
    background-color: $color-primary
    color: #fff

.dropdown
  min-width: 200px
  opacity: 0
  position: absolute
  right: 0
  top: 100%
  transition: opacity .3s ease
  visibility: hidden
  z-index: 1000

.buttonDropdown:hover .dropdown
  opacity: 1
  visibility: visible
